<template>
  <div
    class="header"
    :class="[{ 'floating-form': hasLandingPageSearchForm }, { search: isSearching }]"
  >
    <header>
      <div
        v-show="!hideHeader"
        class="app-header px-3"
        :class="{ fixed: navExpanded }"
      >
        <ZNavbar toggler-breakpoint="xLarge">
          <slot name="logo">
            <ZNavbarBrand :to="localePath({ name: 'index' })">
              <LogoRvezy class="rvezy-logo" />
            </ZNavbarBrand>
          </slot>

          <ZNavbarNav
            v-if="ownerNavbar"
            class="ml-auto"
          >
            <LazyAppNavbarOwner />
          </ZNavbarNav>
          <template v-else>
            <div
              v-if="showLocationSearch || hasLandingPageSearchForm"
              class="d-none d-lg-block search-form-container"
            >
              <Transition name="fade">
                <LazySearchFormSkeleton
                  v-if="isLoading"
                  key="skeleton"
                  :class="[{ 'no-opacity': !showHeaderSearchForm && hasLandingPageSearchForm }]"
                />
                <LazySearchForm
                  v-else
                  key="content"
                  search-on-change
                  class="mr-auto search-desktop"
                  :class="[{ 'no-opacity': !showHeaderSearchForm && hasLandingPageSearchForm }]"
                />
              </Transition>
            </div>

            <ZNavbarToggle @toggle="navExpanded = !navExpanded">
              <fa
                v-if="navExpanded"
                :icon="['far', 'xmark']"
              />
              <template v-else>
                <fa :icon="['far', 'bars']" />
                <span
                  v-if="shouldShowMyRVezyPushNote || inboxNotificationsCount"
                  class="push-note"
                />
              </template>
            </ZNavbarToggle>

            <template v-if="!isXlargeBreakpoint">
              <LazyZNavbarCollapse :show="navExpanded">
                <AppHeaderNavMobile
                  :dashboard-badge="shouldShowMyRVezyPushNote"
                  :inbox-badge="inboxNotificationsCount"
                  class="d-xl-none"
                  @close-nav="navExpanded = false"
                />
              </LazyZNavbarCollapse>
            </template>

            <AppHeaderNavDesktop
              class="d-none d-xl-flex"
              :dashboard-badge="shouldShowMyRVezyPushNote"
              :inbox-badge="inboxNotificationsCount"
            />
          </template>
        </ZNavbar>
      </div>

      <slot name="dashboard-nav">
        <div
          v-if="showDashboardNav"
          class="dashboard-navbar-wrapper d-none d-lg-block"
          data-testid="dashboard-navbar"
        >
          <LazyZContainer fluid>
            <ZNavbar class="py-0">
              <AppDashboardNavbar class="ml-auto" />
            </ZNavbar>
          </LazyZContainer>
        </div>
      </slot>
    </header>

    <div
      v-if="(showLocationSearch || hasLandingPageSearchForm) && !isRequestToBookFlow"
      :class="['d-lg-none mx-3', 'search-mobile', { 'with-height': showHeaderSearchForm && hasLandingPageSearchForm }, { 'pt-3': hideHeader, 'pb-2': !isSearchPage }]"
    >
      <div class="d-flex w-100 align-items-center justify-content-between">
        <SearchMobileFormToggle class="flex-grow-1 width-0" />
        <SearchMobileFiltersToggle
          v-if="isSearching"
          class="ml-2"
        />
      </div>

      <SearchQuickFilters
        v-if="isSearching"
        class="mt-2 d-lg-none"
      />
    </div>
  </div>
</template>

<script>
import routeMixin from '~/mixins/route'
import { handleExitIntent } from '~/lib/exit-intent'

export default {
  mixins: [routeMixin],

  async setup() {
    const showHeaderSearchForm = useShowHeaderSearchForm()
    const { user, isLoggedIn } = useAuthentication()
    const { isLargeBreakpoint, isXlargeBreakpoint, isMediumBreakpoint } = useBreakpoint()
    const { routeBaseName, isRvRentalsOrChildPage, isRvSearchPage } = useBaseName()
    const { logCustomEvent } = await useBraze()

    return {
      user,
      isLoggedIn,
      showHeaderSearchForm,
      isLargeBreakpoint,
      isXlargeBreakpoint,
      isMediumBreakpoint,
      routeBaseName,
      isRvRentalsOrChildPage,
      isRvSearchPage,
      logCustomEvent,
    }
  },

  data() {
    return {
      hasRVezyNotifications: false,
      inboxNotificationsCount: 0,
      navExpanded: false,
      hideHeader: false,
      isLoading: true,
    }
  },

  computed: {
    ownerNavbar() {
      return this.$route.meta?.ownerNavbar
    },

    isHomePage() {
      return ['index', 'index-signup', 'index-signin'].includes(this.routeBaseName)
    },

    showLocationSearch() {
      if (this.isRequestToBookFlow) {
        return false
      }

      if (
        this.routeBaseName === 'review-renter'
        || (this.routeBaseName === 'superhost' && this.$device.isMobileOrTablet)
        || (this.routeBaseName.includes('dashboard') && this.$device.isMobileOrTablet)
      ) {
        return false
      }

      if (this.isRvRentalsOrChildPage) {
        return this.showHeaderSearchForm
      }

      return true
    },

    hasLandingPageSearchForm() {
      if (this.isRvRentalsOrChildPage) {
        return !this.showHeaderSearchForm
      }

      return this.routeBaseName === 'review-renter' || this.isHomePage
    },

    isSearching() {
      return this.isRvSearchPage
    },

    isRequestToBookFlow() {
      return [
        'rv-rental-request-to-book-alias',
        'rv-rental-request-to-book-alias-additionals',
        'dashboard-bookings-id-payment',
      ].includes(this.routeBaseName)
    },

    isSearchPage() {
      return [
        'rv-search',
      ].includes(this.routeBaseName)
    },

    showDashboardNav() {
      return (
        this.routeBaseName.startsWith('dashboard')
        && !/^dashboard-bookings-(pending|payment|paymentSuccess)$/.test(this.routeBaseName)
      )
    },

    shouldShowMyRVezyPushNote() {
      return this.hasRVezyNotifications || (this.user && !this.user.PhoneNumber)
    },
  },

  watch: {
    isLoggedIn: {
      handler(newVal, oldVal) {
        if (newVal || typeof oldVal !== 'undefined') {
          this.checkUserPendingNotifications()
        }
        else if (!newVal) {
          this.resetUserPendingNotifications()
        }
      },

      immediate: true,
    },

    navExpanded: {
      handler(newVal) {
        if (import.meta.server) {
          return
        }

        if (newVal) {
          document?.body.classList.add('body-lock')
        }
        else {
          document?.body.classList.remove('body-lock')
        }
      },

      immediate: true,
    },
  },

  mounted() {
    this.isLoading = false

    handleExitIntent(this.isLoggedIn, this.logCustomEvent)

    window.addEventListener('scroll', this.onScroll)
    this.hideHeader = Boolean(window?.scrollY > 0 && !this.isMediumBreakpoint) && this.isSearching
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    onScroll($event) {
      this.hideHeader = Boolean($event?.target?.scrollingElement?.scrollTop > 0 && !this.isMediumBreakpoint && this.isSearching)
    },

    async checkUserPendingNotifications() {
      try {
        if (this.isLoggedIn) {
          // TODO: API
          const model = await this.$rentals('/api/Message/user-pending-notifications-indicator')
          this.hasRVezyNotifications = model.HasRVezyNotifications
          this.inboxNotificationsCount = model.TotalInboxNotifications
        }
        else {
          this.resetUserPendingNotifications()
        }
      }
      catch (ex) {
        if (ex.response?.data?.StatusCode !== 401) {
          this.$captureError(ex)
        }
        // Fail Silently when not authenticated
      }
    },

    resetUserPendingNotifications() {
      this.hasRVezyNotifications = false
      this.inboxNotificationsCount = 0
    },
  },
}
</script>

<style lang="scss" scoped>
.header {

  .search-form-container {
    position: relative;
    width: 27.5rem;
    height: 3.375rem;
    margin-right: auto;
  }

  @include media-max-size(large) {
    .rvezy-logo {
      width: 4.25rem;
      height: 2.125rem;
    }

    .znavbar-toggler {
      font-size: 1.25rem;
    }
  }

  :deep(.znavbar) {
    padding: 0.5rem 0;

    .znav-item {
      > a {
        padding-left: 0.875rem;
        padding-right: 0.875rem;
      }

      &.zdropdown {
        .zdropdown-menu {
          border-color: getColor('highlight-100');

          .zdropdown-item {
            color: getColor('primary-500');
            padding: 0.25rem 1.25rem;

            &:hover {
              background-color: getColor('highlight-50');
            }
          }
        }
      }
    }
  }

  .znavbar-toggler {
    border: none;
    width: 2.75rem;
    position: relative;
  }

  :deep(.locale) {
    text-transform: uppercase;
  }

  @include media-max-size(large) {
    &.floating-form {
      > header {
        position: relative;
        z-index: 1;
        background-color: #fff;
      }

      .search-mobile {
        transition: top ease-in-out 200ms;
        position: absolute;
        left: -1rem;
        right: -1rem;
        background-color: #fff;
        padding-inline: 1rem;
        z-index: 0;
        top: -250%;
        display: flex;

        &.with-height {
          top: 100%;
        }
      }
    }
  }
}
.search-desktop {
  transition: opacity ease-in-out 200ms;
  opacity: 1;

  &.no-opacity {
    opacity: 0;
    z-index: -1;
  }
}

.dashboard-navbar-wrapper {
  border-top: 0.0625rem solid getColor('primary-100');
  border-bottom: 0.0625rem solid getColor('primary-100');

  :deep(.znav-link) {
    @include regular-weight;
    color: getColor('primary-500');

    &.nuxt-link-active {
      @include semi-bold;
      position: relative;

      &:focus {
        color: getColor('highlight-500');
      }
    }
  }
}

.app-header.fixed {
  @include media-max-size(xLarge) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $headerZindex;
    overflow-y: auto;
    background-color: #fff;
  }
}

:deep(.more-filters) {
  padding: 0.7rem 0.9375rem;
  font-size: 1.125rem;
}

.width-0 {
  width: 0;
}
</style>
